import { GAIDObj } from '@/consts'

const product = import.meta.env.VITE_API_PRODUCT
const GAID = GAIDObj[product]
class GAManager {
  init() {
    // 加载google广告相关的js
    this.loadGaJS()
  }

  // 加载ga js
  loadGaJS() {
    const script = document.createElement('script')
    script.setAttribute('async', true)
    script.setAttribute(
      'src',
      'https://www.googletagmanager.com/gtag/js?id=' + GAID
    )
    document.head.appendChild(script)

    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }
    window.gtag = gtag
    gtag('js', new Date())
    gtag('config', GAID)
    gtag('config', GAID, { transport_type: 'beacon' })
  }

  // 打点
  track(eventName, data = {}) {
    // GA打点
    window.gtag && window.gtag('event', eventName, data)
  }
}

export default new GAManager()
