export const tiktokIdObj = {
  mintfreegame: 'CHDL9B3C77U3K6O1GKAG',
  enjoy8games: '',
  fun8games: '',
  minifungames: '',
  mini8games: '',
  fun1001games: 'CI4LJ0RC77U8RIVTFST0',
}

export const pubIdObj = {
  mintfreegame: 'ca-pub-5419845534727525',
  enjoy8games: 'ca-pub-5977351750691001',
  fun8games: 'ca-pub-5419845534727525',
  minifungames: 'ca-pub-5419845534727525',
  mini8games: 'ca-pub-5977351750691001',
  fun1001games: 'ca-pub-5977351750691001',
}

/**
 * 域名对应的GAID，用于打点
 */
export const GAIDObj = {
  mintfreegame: 'UA-167264122-2',
  enjoy8games: 'UA-258440359-1',
  fun8games: 'UA-167264122-4',
  minifungames: 'UA-167264122-5',
  mini8games: 'UA-258440359-3',
  fun1001games: 'UA-258440359-4',
}

/**
 * 域名对应的GTMID，用于打点
 */
export const GTMIDObj = {
  mintfreegame: 'GTM-TMTHLBN',
  enjoy8games: 'GTM-MMCLFD4',
  fun8games: 'GTM-PF6CCZG',
  minifungames: 'GTM-NMP6VM4',
  mini8games: 'GTM-NZLFFSB',
  fun1001games: 'GTM-KDXNMCS',
}

/**
 * 所有频道列表
 */
export const GAME_CHANNEL = {
  RECOMMEND: 'brsgame_top',
  ALL: 'brsgame_recommend',
}

/**
 * 游戏区域
 */
export const GAME_AREAS = {
  TOP_PICKS: 'top_picks',
  NEW_GAMES: 'new_games',
  FAVORITE_RECOMMEND: 'favorite_recommend',
  RECENT_RECOMMEND: 'recent_recommend',
  DETAIL_RECOMMEND: 'detail_recommend',
  MOBILE_ALL: 'mobile_all',
}

/**
 * 广告位名称
 */
export const ADS_SLOT_ID = {
  mintfreegame: {
    MOBILE_HOME_MIDDLE_1: '6334985588',
    MOBILE_DETAIL_MASK_1: '3319893713',
    MOBILE_DETAIL_1: '3671959263',
    PC_HOME_RIGHT_1: '5496779469',
    PC_HOME_RIGHT_2: '9052881097',
    PC_CATEGORY_RIGHT_1: '1174391075',
    PC_CATEGORY_RIGHT_2: '4738715038',
    PC_FAVORITE_RIGHT_1: '2191871929',
    PC_FAVORITE_RIGHT_2: '9878790254',
    PC_RECENT_RIGHT_1: '7252626915',
    PC_RECENT_RIGHT_2: '5939545248',
    PC_DETAIL_RIGHT_1: '5950390823',
    PC_DETAIL_RIGHT_2: '2250780974',
    PC_DETAIL_MIDDLE_1: '8095022648',
  },
  enjoy8games: {
    MOBILE_HOME_MIDDLE_1: '6334985588',
    MOBILE_DETAIL_MASK_1: '3319893713',
    MOBILE_DETAIL_1: '8256650963',
    PC_HOME_RIGHT_1: '5630487625',
    PC_HOME_RIGHT_2: '4317405956',
    PC_CATEGORY_RIGHT_1: '4588868699',
    PC_CATEGORY_RIGHT_2: '9516658095',
    PC_FAVORITE_RIGHT_1: '8203576429',
    PC_FAVORITE_RIGHT_2: '6890494757',
    PC_RECENT_RIGHT_1: '5577413083',
    PC_RECENT_RIGHT_2: '9649623683',
    PC_DETAIL_RIGHT_1: '6352272854',
    PC_DETAIL_RIGHT_2: '8336542012',
    PC_DETAIL_MIDDLE_1: '8551879026',
  },
  fun8games: {
    MOBILE_HOME_MIDDLE_1: '6334985588',
    MOBILE_DETAIL_MASK_1: '3319893713',
    MOBILE_DETAIL_1: '9839235497',
    PC_HOME_RIGHT_1: '7213072150',
    PC_HOME_RIGHT_2: '3273827147',
    PC_CATEGORY_RIGHT_1: '8587230557',
    PC_CATEGORY_RIGHT_2: '9787264604',
    PC_FAVORITE_RIGHT_1: '8334582138',
    PC_FAVORITE_RIGHT_2: '7161101265',
    PC_RECENT_RIGHT_1: '5708418795',
    PC_RECENT_RIGHT_2: '4395337120',
    PC_DETAIL_RIGHT_1: '3082255457',
    PC_DETAIL_RIGHT_2: '6829928777',
    PC_DETAIL_MIDDLE_1: '3221856259',
  },
  minifungames: {
    MOBILE_HOME_MIDDLE_1: '6334985588',
    MOBILE_DETAIL_MASK_1: '3319893713',
    MOBILE_DETAIL_1: '7737291119',
    PC_HOME_RIGHT_1: '8345194578',
    PC_HOME_RIGHT_2: '4405949560',
    PC_CATEGORY_RIGHT_1: '3798046105',
    PC_CATEGORY_RIGHT_2: '8153622889',
    PC_FAVORITE_RIGHT_1: '5527459546',
    PC_FAVORITE_RIGHT_2: '6232637753',
    PC_RECENT_RIGHT_1: '2901296208',
    PC_RECENT_RIGHT_2: '5335887857',
    PC_DETAIL_RIGHT_1: '6341847760',
    PC_DETAIL_RIGHT_2: '5028766091',
    PC_DETAIL_MIDDLE_1: '3715684429',
  },
  mini8games: {
    MOBILE_HOME_MIDDLE_1: '6334985588',
    MOBILE_DETAIL_MASK_1: '3319893713',
    MOBILE_DETAIL_1: '6480124113',
    PC_HOME_RIGHT_1: '5330693976',
    PC_HOME_RIGHT_2: '2336030820',
    PC_CATEGORY_RIGHT_1: '3059753857',
    PC_CATEGORY_RIGHT_2: '6807427173',
    PC_FAVORITE_RIGHT_1: '2868182161',
    PC_FAVORITE_RIGHT_2: '6651799917',
    PC_RECENT_RIGHT_1: '9770246834',
    PC_RECENT_RIGHT_2: '4218537253',
    PC_DETAIL_RIGHT_1: '7581738204',
    PC_DETAIL_RIGHT_2: '1016329858',
    PC_DETAIL_MIDDLE_1: '2905455584',
  },
  fun1001games: {
    MOBILE_HOME_MIDDLE_1: '2049310504',
    MOBILE_DETAIL_MASK_1: '3156123011',
    MOBILE_DETAIL_1: '5693941469',
    PC_HOME_RIGHT_1: '9205525654',
    PC_HOME_RIGHT_2: '5298317652',
    PC_CATEGORY_RIGHT_1: '6815451441',
    PC_CATEGORY_RIGHT_2: '7936961423',
    PC_FAVORITE_RIGHT_1: '1371553075',
    PC_FAVORITE_RIGHT_2: '1359072649',
    PC_RECENT_RIGHT_1: '1592373913',
    PC_RECENT_RIGHT_2: '6419827633',
    PC_DETAIL_RIGHT_1: '1179981383',
    PC_DETAIL_RIGHT_2: '3457540801',
    PC_DETAIL_MIDDLE_1: '9988409692',
  },
}

// 埋点事件类型
export const EVENT_TYPE = {
  ALL: {
    ADS_CLICK: 'ads_click_afc',
    ADS_CLICK_MODE2: 'mode2_ads_click_afc',
  },
}

// 设备唯一标识，接口参数uuid
export const UUID_KEY = 'device_uuid'

// 玩过的游戏列表key
export const PLAYED_KEY = 'played_list'

// 喜欢的游戏列表key
export const FAVORITE_KEY = 'favorite_list'
